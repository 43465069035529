import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import VConsole from 'vconsole';

new VConsole({ theme: 'dark' });
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
