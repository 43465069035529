<template>
  <div>
    <video src="" id="abc"></video>
  </div>
</template>

<script>
import 'aliyun-webrtc-sdk'
export default {
  components: {
  },
  mounted() {

    var aliWebrtc = new AliRtcEngine();



    aliWebrtc.isSupportScreenShare() ? console.log("支持屏幕共享") : console.log("不支持屏幕共享");
    aliWebrtc.isSupport().then((res) => {
      //支持webrtc
      console.log("支持webrtc");


      let video = document.getElementById("abc");
















      aliWebrtc.setVideoProfile({
        width: 960,
        height: 540,
        frameRate: 10
      }, 1);







      aliWebrtc.configLocalScreenPublish = true;


      aliWebrtc.joinChannel({
        userid: "testuser0",         // 用户ID，只能由数字、字母、下划线组成
        channel: "test0",        // 频道
        appid: "efs1mehd",          // 应用ID
        nonce: "AK-ca554e75-b193-4c80-9b22-d08de645819e",          // 随机码
        timestamp: "1673258058",      // 时间戳
        gslb: ["https://rgslb.rtc.aliyuncs.com"],           // gslb服务地址
        token: "f011ca2a79c947584debccf558d6cc78c874f9dbced0f42a9bb79fa2432752e7",          // 令牌
      }, "测试用户0").then(() => {
        // 入会成功
        console.log("连接房间成功")
        aliWebrtc.publish().then(() => {

          aliWebrtc.configLocalScreenPublish = true;
          console.log("推流成功，正在推流...");
        }, (error) => {
          console.log("推流失败：", error.message);
        });

        aliWebrtc.on('onJoin', (data) => {
          console.log("有人接入：", data.userId);
          console.log("有人接入：", data.displayName + " 加入频道");
        });





      }, (error) => {
        // 入会失败，打印错误内容，可以看到失败原因
        console.log("连接房间失败：", error.message);
      });





















      aliWebrtc.startPreview(
        video    // html中的video元素
      ).then(() => {
        //预览成功
        console.log("预览成功");




        aliWebrtc.setVideoProfile({
          width: 960,
          height: 540,
          frameRate: 10
        }, 1);

        aliWebrtc.configLocalScreenPublish = true;








        aliWebrtc.joinChannel({
          userid: "testuser0",         // 用户ID，只能由数字、字母、下划线组成
          channel: "test0",        // 频道
          appid: "efs1mehd",          // 应用ID
          nonce: "AK-ca554e75-b193-4c80-9b22-d08de645819e",          // 随机码
          timestamp: "1673258058",      // 时间戳
          gslb: ["https://rgslb.rtc.aliyuncs.com"],           // gslb服务地址
          token: "f011ca2a79c947584debccf558d6cc78c874f9dbced0f42a9bb79fa2432752e7",          // 令牌
        }, "测试用户0").then(() => {
          // 入会成功
          console.log("连接房间成功")
          aliWebrtc.publish().then(() => {
            console.log("推流成功，正在推流...");
          }, (error) => {
            console.log("推流失败：", error.message);
          });

          aliWebrtc.on('onJoin', (data) => {
            console.log("有人接入：", data.userId);
            console.log("有人接入：", data.displayName + " 加入频道");
          });





        }, (error) => {
          // 入会失败，打印错误内容，可以看到失败原因
          console.log("连接房间失败：", error.message);
        });
      }).catch((error) => {
        // 预览失败
        console.log("预览失败");
      });


    }).catch((error) => {
      //不支持webrtc
      console.log("不支持webrtc", error);
    })
  },
}
</script>
<style>
video {
  width: 80vw;
  height: 60vw;
}
</style>
